import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createCashPayment, deleteOneCashPayment, getCashPayments, getOneCashPayment, updateCashPayment, } from './actions';
var initialState = {
    cashRegisterPayments: { docs: [], meta: {} },
    cashRegisterPayment: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'cashRegister',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createCashPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createCashPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.cashRegisterPayment = action.payload.data;
        })
            .addCase(createCashPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateCashPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateCashPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.cashRegisterPayment = action.payload.data;
        })
            .addCase(updateCashPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneCashPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneCashPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.cashRegisterPayment = action.payload.data;
        })
            .addCase(getOneCashPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getCashPayments.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getCashPayments.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.cashRegisterPayments = action.payload;
        })
            .addCase(getCashPayments.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOneCashPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneCashPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.cashRegisterPayments.docs = state.cashRegisterPayments.docs.filter(function (expense) { return expense._id !== action.meta.arg.cashPaymentId; });
        })
            .addCase(deleteOneCashPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
